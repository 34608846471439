import { doc, getFirestore, onSnapshot } from '@firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_LAUNDRY } from '../constants/business';
import { BUSINESS_ID_FACTORY } from '../constants/factory';
import { PAYMENT_ID_ALIPAY, PAYMENT_ID_GRABPAY, PAYMENT_ID_PAYNOW_STRIPE, PAYMENT_ID_STRIPE, PAYMENT_ID_WECHATPAY } from '../constants/payment';
import toPaymentMethodTitle from '../utils/toPaymentMethodTitle';
import { useIsLimited, useRealmId } from './AuthenticationContext';

const RealmContext = createContext();

export function RealmProvider({ children }) {
  const realmId = useRealmId();
  const [relam, setRealm] = useState();

  useEffect(() => {
    if (realmId) {
      const handler = onSnapshot(doc(getFirestore(), `realms/${realmId}`), setRealm);
      return handler;
    }
  }, [realmId]);

  return (
    <RealmContext.Provider value={relam}>
      {children}
    </RealmContext.Provider>
  );
}

const toDisplayName = (context, key) => {
  const { serviceTypes, businessCodes, accounts, serviceCodes, serviceNames, stores, webapps, collectionPoints, paymentMethods } = context?.data() || {};

  let name = (businessCodes && businessCodes[key])
    || (stores && stores[key])
    || (webapps && webapps[key])
    || (accounts && accounts[key])
    || (serviceNames && serviceNames[key])
    || (serviceCodes && serviceCodes[key])
    || (paymentMethods && paymentMethods[key])
    || (serviceTypes && serviceTypes[key])
    || '';

  if ((!name) && Array.isArray(collectionPoints)) {
    const place = collectionPoints.find(place => place.name === key);
    name = place?.name;
  }

  if (name) {
    return name;
  } else if (key && (`${key}`.length <= 6)) {
    // Backward support only
    return key;
  } else {
    return '';
  }
};

export const useBusinesses = (businessCode, sector) => {
  const context = useContext(RealmContext);
  const { services } = context?.data() || {};

  return (services || [])
    .filter(service => (!businessCode) || (Array.isArray(service.codes) && (service.codes.indexOf(businessCode) >= 0)))
    .filter(service => (!sector) || (service.sector === sector));
}
export const usePublicBusinesses = () => {
  const context = useContext(RealmContext);
  const { publicServices } = context?.data() || {};
  return (Array.isArray(publicServices) && (publicServices.length > 0)) ? publicServices : null;
}
export const useDisplayName = (nameKey) => {
  const intl = useIntl();

  if (nameKey === BUSINESS_ID_FACTORY) {
    return intl.formatMessage({ defaultMessage: 'Central factory' });
  } else {
    return toDisplayName(useContext(RealmContext), nameKey);
  }
};
export const useToDisplayName = () => {
  const context = useContext(RealmContext);
  const intl = useIntl();

  return (nameKey) => {
    if (nameKey === BUSINESS_ID_FACTORY) {
      return intl.formatMessage({ defaultMessage: 'Central factory' });
    } else {
      return toDisplayName(context, nameKey);
    }
  }
}
export const useToStoreCode = () => {
  const context = useContext(RealmContext);
  const { businessCodes } = context?.data() || {};

  return (businessCode) => (businessCodes && businessCodes[businessCode]) || '';
}
export const useBusinessName = (serviceId) => {
  return toDisplayName(useContext(RealmContext), serviceId);
}
export const useToBusinessName = () => {
  const context = useContext(RealmContext);

  return (serviceId) => {
    if (serviceId === BUSINESS_ID_FACTORY) {
      return `Central Factory`;
    } else {
      return toDisplayName(context, serviceId);
    }
  }
}
export const useOrderNoDisplay = (args) => {
  const { business, businessCode, orderNo } = args || {};
  const name = useDisplayName(businessCode || business?.id);
  return `#${orderNo}${name ? ` (${name})` : ''}`;
}
export const useRealmContextReady = () => Boolean(useContext(RealmContext));
export const useCurrency = () => useContext(RealmContext)?.get('currency');
export const useCurrencyMark = () => {
  const { currencyMark, currencySymbol } = useContext(RealmContext)?.data() || {};
  return currencyMark || currencySymbol || '';
}
export const useDialCode = () => useContext(RealmContext)?.get('telephone.dialCode');
export const useTimeZone = () => useContext(RealmContext)?.get('timeZone');
export const useCountryCode = () => useContext(RealmContext)?.get('country.abbreviation');
export const useCountryName = () => useContext(RealmContext)?.get('country.name');
export const useCityName = () => useContext(RealmContext)?.get('location.title');
export const useGeoLocation = () => useContext(RealmContext)?.get('location');
export const useIsCentralFactoryEnabeld = () => useContext(RealmContext)?.get('factory');
export const useAccountingSalesByClose = () => useContext(RealmContext)?.get('salesByClose');
export const useHaveWebApp = () => Boolean(useContext(RealmContext)?.get('onlineLicences')?.length > 0);
export const useHaveStore = () => Boolean(useContext(RealmContext)?.get('storeLicences')?.length > 0);
export const useHavePlant = () => Boolean(useContext(RealmContext)?.get('plantLicence'));
export const useStoreMap = () => useContext(RealmContext)?.get('stores');
export const useStripeAccount = () => useContext(RealmContext)?.get('stripeAccount');
export const useOnlinePaymentMethods = (isPOS) => {
  const currency = useCurrency();
  const stripeAccount = useStripeAccount();
  const posStripeMethods = useContext(RealmContext)?.get('posStripeMethods');

  if (stripeAccount) {
    return [
      { id: PAYMENT_ID_STRIPE, title: toPaymentMethodTitle(PAYMENT_ID_STRIPE) },
      ...(currency === 'SGD') ? [{ id: PAYMENT_ID_PAYNOW_STRIPE, title: toPaymentMethodTitle(PAYMENT_ID_PAYNOW_STRIPE) }] : [],
      { id: PAYMENT_ID_GRABPAY, title: toPaymentMethodTitle(PAYMENT_ID_GRABPAY) },
      { id: PAYMENT_ID_WECHATPAY, title: toPaymentMethodTitle(PAYMENT_ID_WECHATPAY) },
      { id: PAYMENT_ID_ALIPAY, title: toPaymentMethodTitle(PAYMENT_ID_ALIPAY) }
    ].filter(data => (!isPOS) || (Array.isArray(posStripeMethods) && (posStripeMethods.indexOf(data.id) >= 0))).map(data => ({ ...data, photoId: data.id }));
  }
}
export const usePaymentMethodTitles = () => useContext(RealmContext)?.get('paymentMethods');
export const useStores = () => {
  const context = useContext(RealmContext);
  const storeLicences = context?.get('storeLicences');

  return (storeLicences || []).map(id => ({ id, title: toDisplayName(context, id) }))
    .sort((a, b) => `${a.title}`.localeCompare(`${b.title}`));
};
export const useAppMap = () => useContext(RealmContext)?.get('webapps');
export const useApps = () => {
  const context = useContext(RealmContext);
  const onlineLicences = context?.get('onlineLicences');
  const isLimited = useIsLimited();

  return (onlineLicences || [])
    .filter(code => !isLimited(code))
    .map(id => ({ id, title: toDisplayName(context, id) }));
}
export const useDepartments = () => useContext(RealmContext)?.get('departments');
export const useBusinessSectors = () => useContext(RealmContext)?.get('sectors');
export const useBusinessSector = (serviceId) => {
  const context = useContext(RealmContext);
  const { services } = context?.data() || {};
  return services?.find(service => service.id === serviceId)?.sector;
}
export const useHasItemBusiness = () => {
  const sectors = useContext(RealmContext)?.get('sectors');

  return Boolean((sectors || []).find(sector => [BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE].indexOf(sector) >= 0));
}
export const useHasBusinessSector = (sector) => {
  const sectors = useContext(RealmContext)?.get('sectors');
  return Array.isArray(sectors) && (sectors.indexOf(sector) >= 0);
}
export const usePOSSettings = () => useContext(RealmContext)?.get('posSettings') || {};
export const useLogisticSettings = () => useContext(RealmContext)?.get('logisticSettings') || {};
export const useProductionSettings = () => useContext(RealmContext)?.get('productionSettings') || {};
export const useLabelSettings = () => useContext(RealmContext)?.get('labelSettings') || {};
export const useMemberSettings = () => useContext(RealmContext)?.get('memberSettings') || {};
export const useIsWhitelistEnforced = (clientType) => Boolean(useContext(RealmContext)?.get(`whiteList.${clientType}`));
export const useCollectionPoints = () => useContext(RealmContext)?.get('collectionPoints');
export const useCompanies = () => useContext(RealmContext)?.get('companys');
export const useGSTRate = (businessCode) => {
  const context = useContext(RealmContext);
  const companys = context?.get('companys');

  if (Array.isArray(companys)) {
    const company = companys.find(company => company?.codes?.find(code => code === businessCode));
    return company?.gstRate;
  }
}
export const useIsManualRealm = () => useContext(RealmContext)?.get('manual');
export const useIsInternalTest = () => useContext(RealmContext)?.get('internalTest');
export const useOnlineUpgradeReady = () => useContext(RealmContext)?.get('upgradeReady');
export const usePriceLists = () => useContext(RealmContext)?.get('priceLists');
export const useEmailTemplates = () => useContext(RealmContext)?.get('emailTemplates');
export const useReceiptTemplates = () => useContext(RealmContext)?.get('receiptTemplates');
export const useLabelTemplates = () => useContext(RealmContext)?.get('labelTemplates');